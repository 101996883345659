import { Col, Row } from 'components/Core/Grid';
import React, { useState } from 'react';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import WebsiteImage from 'components/WebsiteImage';

import { StyledSectionLayout } from './styles';

const AwardsNamesImages = ({ entries }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const names = entries?.filter((entry) => entry.templateId.includes('name'));

  return (
    <StyledSectionLayout>
      <Row gutter={80} justify="center" align="middle">
        <Col xs={24} sm={15} lg={13}>
          {names?.map((name, index) => (
            <div
              onMouseEnter={() => setActiveIndex(index + 1)}
              onClick={() => setActiveIndex(index + 1)}
              className={`award-name ${activeIndex === index + 1 ? 'active' : ''}`}
            >
              {entries && <ContentfulRichText richText={getEntry(`name-${index + 1}`, entries)} />}
            </div>
          ))}
        </Col>
        <Col xs={24} sm={9} lg={7}>
          <WebsiteImage {...getEntry(`image-${activeIndex}`, entries)} />
        </Col>
      </Row>
    </StyledSectionLayout>
  );
};

export default AwardsNamesImages;
