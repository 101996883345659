import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

const StyledSectionLayout = styled(SectionLayout)`
  text-align: center;

  .active {
    h5 {
      color: var(--darkest);
    }
  }

  h5 {
    font-size: var(--font-size-h4);
    width: max-content;
    color: var(--grey-6);
    margin-bottom: 30px;
  }

  .award-name {
    margin: 0 10px;
  }

  .award-name,
  h5,
  .rich-text--container {
    display: inline-block;
    max-width: 100%;
    width: max-content;
  }

  @media (max-width: 575px) {
    h5 {
      font-size: var(--font-size-mobile-h5);
      margin-bottom: 20px;
    }

    img {
      max-width: 100%;
      margin: 40px auto 0 auto;
      height: auto;
    }
    .award-name {
      margin: 0px;
    }
  }
`;

export { StyledSectionLayout };
